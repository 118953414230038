import { DialogIdent, useDialogStore } from '@/stores/useDialogStore';
import { DialogResponses } from '~/stores/useDialogStore';

export default function useDeleteVerificationFileDialog() {
  const dialogStore = useDialogStore();
  type openOptions = { id: string };
  return {
    open: async (options: openOptions) => {
      return dialogStore.openDialog(
        DialogIdent.DELETE_VERIFICATION_FILE,
        options,
      );
    },
    getData: () => {
      return dialogStore.getDialogData<openOptions>(
        DialogIdent.DELETE_VERIFICATION_FILE,
      );
    },
    close: (data?: any) => {
      dialogStore.closeDialog(data ?? DialogResponses.SUCCESS);
    },
    cancel: () => {
      dialogStore.closeDialog(DialogResponses.CANCEL);
    },
  };
}
